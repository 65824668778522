import { FC, ReactNode } from 'react';
import { FormFieldHint } from 'evergreen-ui';

type Props = {
  children?: ReactNode;
  validationMessage?: string; // 表示制御で利用
};

const FormFieldHint_: FC<Props> = ({ children, validationMessage }) => {
  return (
    <>
      {!validationMessage && children && (
        <FormFieldHint
          wordBreak="break-all"
          marginTop={6}>{children}</FormFieldHint>
      )}
    </>
  );
};

export default FormFieldHint_;
