import * as React from 'react';
import clsx from 'clsx';
import { TextInput, TextInputProps } from 'evergreen-ui';

import FormFieldHint from '../FormFieldHint';
import FormFieldLabel from '../FormFieldLabel';
import FormFieldValidationMessage from '../FormFieldValidationMessage';
import { ValidationError } from 'utils/validationError';
import { useTranslation } from 'src/i18n';

export { default as Search } from './Search';

export type Props = TextInputProps & {
  hint?: string;
  id: string;
  inputValue: string | null;
  label?: string;
  onChangeValue: (value: string) => void;
  validationError?: ValidationError[];
  validationMessage?: string;
};

const InputText: React.FC<Props> = ({
  className = '',
  isInvalid,
  hint,
  id,
  inputValue,
  label,
  onChangeValue,
  style,
  validationError = [],
  validationMessage,
  textAlign,
  required,
  ...textInputProps
}: Props) => {
  const { t } = useTranslation();
  const error = React.useMemo(
    () => validationError.find(errors => errors.field === id),
    [validationError, id],
  );
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onChangeValue(event.target.value);
    }, [onChangeValue],
  );
  return (
    <div
      className={clsx(className, 'input-text')}
      style={style ?? {}}>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <TextInput
        id={id}
        data-cy={`input-${id}`}
        isInvalid={isInvalid || !!error}
        onChange={onChange}
        value={inputValue ?? ''}
        width="100%"
        {...textInputProps}
      />
      {(!isInvalid && !error) && (
        <FormFieldHint>{hint}</FormFieldHint>
      )}
      {(isInvalid || !!error) && (
        <FormFieldValidationMessage>
          {validationMessage || error && t(`validation.error.${error.code}`)}
        </FormFieldValidationMessage>
      )}
      <style jsx>
        {`
          .input-text {
            :global(input) {
              text-align: ${textAlign};
            }
            :global(p[role=alert]) {
              white-space: nowrap;
            }
            :global(svg[data-icon=error]) {
              min-width: 14px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default InputText;
