import * as React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { BoxModel, Color } from 'src/theme';
import { WIDTH } from 'src/css/authentication';
import { useTranslation } from 'src/i18n';

const ChangeLanguage: React.FC = () => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const onChangeLang = React.useCallback((lang: string) => {
    router.push(router.pathname, router.asPath, { locale: lang });
  }, [router]);

  return (
    <>
      <div className="change-lang-container">
        <div
          className={clsx('lang', { current: i18n.language === 'ja' })}
          onClick={() => onChangeLang('ja')}>
          日本語
        </div>
        <div
          className={clsx('lang', { current: i18n.language === 'en' })}
          onClick={() => onChangeLang('en')}>
          English
        </div>
      </div>
      <style jsx>{`
        .change-lang-container {
          display: flex;
          justify-content: flex-end;
          width: ${WIDTH};
          margin: 16px auto 0;
        }
        .lang {
          align-items: center;
          border-radius: 4px;
          border: ${BoxModel.BorderDefault};
          cursor: pointer;
          display: flex;
          font-size: 12px;
          height: 24px;
          justify-content: center;
          width: 72px;
          :first-of-type {
            margin-right: 8px;
          }
          &.current {
            color: #fff;
            background-color: ${Color.Primary};
          }
        }
      `}</style>
    </>
  );
};

export default ChangeLanguage;
