import * as React from 'react';
import { FormFieldLabel } from 'evergreen-ui';

import RequiredFormLabel from './RequiredFormLabel';
import { Color } from 'src/theme';

type Props = {
  children: React.ReactNode;
  required?: boolean;
};

const FormFieldLabel_: React.FC<Props> = ({ children, required }: Props) => {
  return (
    <>
      {children && (
        <div className="form-label">
          <FormFieldLabel color={Color.TextDefault}>{children}</FormFieldLabel>
          {required && (
            <RequiredFormLabel />
          )}
        </div>
      )}
      <style jsx>{`
        .form-label {
          display: flex;
          margin-bottom: 8px;
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};

export default FormFieldLabel_;
