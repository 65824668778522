import * as React from 'react';

import { Color } from 'src/theme';
import { useTranslation } from 'src/i18n';

type Props = {
  style?: React.CSSProperties;
};

const RequiredFormLabel: React.FC<Props> = ({ style }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="required" style={style ?? {}}>{t('required.form.label')}</div>
      <style jsx>{`
        .required {
          color: ${Color.TextWarning};
          font-size: 10px;
          margin-left: 0;
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};

export default RequiredFormLabel;
