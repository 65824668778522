import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

type SetLoading = (isLoading: boolean) => void;

export const LoadingContext = React.createContext<SetLoading>(() => {});

const LoadingCover: React.FC = () => {
  return (
    <div className="loading loading-background" data-cy="loading-cover">
      <CircularProgress className="circular-progress" />
      <style global jsx>
        {`
          .loading-background {
            align-items: center;
            background-color: rgba(4, 4, 4, 0.1);
            display: flex;
            height: 100%;
            left: 0;
            position: fixed;
            text-align: center;
            top: 0;
            width: 100%;
            z-index: 999;
          }
          .loading .circular-progress {
            margin: auto;
            z-index: 1000;
          }
        `}
      </style>
    </div>
  );
};

export default LoadingCover;
