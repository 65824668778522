import css from 'styled-jsx/css';

import { BoxModel, Color } from 'src/theme';

export const WIDTH = '450px';

const styles = css`
  .signin-container {
    background-color: ${Color.BackgroundContent};
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }
  .signin-inner {
    border-radius: 8px;
    border: ${BoxModel.BorderDefault};
    bottom: 0;
    height: 502px;
    left: 0;
    margin: 0 auto;
    overflow: hidden;
    width: ${WIDTH};
    padding: 48px 40px 36px;
  }
  .signin-title {
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
  }
  .signin-form {
    display: flex;
    flex-flow: column;
    gap: 24px;
    margin-top: 2.4rem;
  }
  .message-failure {
    color: ${Color.TextWarning};
    font-size: 12px;
    margin-bottom: .8rem;
  }
  .link {
    color: ${Color.TextLink};
  }
  .submit-container {
    align-items: center;
    display: flex;
    height: 4.8rem;
    justify-content: space-between;
  }
  .message-success {
    margin-left: auto;
    margin-top: 1.6rem;
  }
`;

export default styles;
