import { FC, ReactNode } from 'react';
import { FormFieldValidationMessage } from 'evergreen-ui';

type Props = {
  children?: ReactNode;
};

const FormFieldValidationMessage_: FC<Props> = ({ children }) => {
  return (
    <>
      {children && (
        <FormFieldValidationMessage
          className="form-field-validation-message"
          wordBreak="break-all"
          marginTop={6}>{children}</FormFieldValidationMessage>
      )}
      <style jsx>{`
        :global(.form-field-validation-message svg) {
          min-width: 14px;
        }
      `}</style>
    </>
  );
};

export default FormFieldValidationMessage_;
