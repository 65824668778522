import * as React from 'react';
import { SearchInput } from 'evergreen-ui';

export type Props = {
  className?: string;
  disabled?: boolean;
  id: string;
  inputValue: string | null;
  label?: string;
  onChangeValue: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  autoFocus?: boolean;
};

const InputSearch: React.FC<Props> = ({
  className,
  disabled = false,
  id,
  inputValue,
  onChangeValue,
  placeholder = '',
  style,
  autoFocus,
}) => {
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onChangeValue(event.target.value);
    },
    [onChangeValue],
  );
  return (
    <div className={className} style={style ?? {}}>
      <SearchInput
        id={id}
        data-cy={`search-${id}`}
        onChange={onChange}
        placeholder={placeholder}
        value={inputValue ?? ''}
        autoFocus={autoFocus}
        width="100%"
        disabled={disabled}
      />
    </div>
  );
};

export default InputSearch;
